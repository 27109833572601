@import '../../../base/assets/scss/_base/config';

@mixin container-height() {
  height: 140px;

  @media screen and (min-width: 901px) {
    height: 55px;
  }
}

.m-aol-se {
  &_without_notif_banner {
    margin-bottom: 30px;

    .m-aol-se__container_1 {
      height: 120px;

      @media screen and (min-width: 901px) {
        height: 55px;
      }
    }
  }

  &.election_day_app {
    margin-bottom: 0px !important;
  }

  @media screen and (min-width: 901px) {
    margin-bottom: 30px;
  }

  &__middle {
    .m-aol-se__content {
      @include container-height();
      margin-left: 20px;
      font-size: 12px;
      font-family: $font-family-poppins;
      font-weight: 600;
      line-height: 20px;
      color: $picasso;
      display: flex;
      align-items: center;
      min-width: 150px;

      &_main {
        position: relative;
        top: -1.5px;
      }

      svg {
        width: 16px;
        height: 10px;
        path {
          fill: $picasso;
        }
      }
    }
  }

  &__link {
    text-decoration: none;
  }

  &__image {
    position: relative;
    top: 0px;
  }

  &__container_1 {
    display: flex;
    justify-content: flex-start;
    @include container-height();

    @media screen and (min-width: 901px) {
      display: none;
    }

    .m-aol-se {
      &__left {
        display: flex;
        justify-content: flex-start;
        position: absolute;
      }

      &__right {
        flex-grow: 1;
        display: flex;
        justify-content: flex-end;

        .m-aol-se__image {
          @media screen and (max-width: 360px) {
            display: none;
          }
        }
      }
    }
  }

  &__container_2 {
    @include container-height();
    display: none;
    justify-content: space-between;
    background-color: #fff;

    .m-aol-se {

      &__extra {
        flex-grow: 1;
      }

      &__main {
        display: flex;
        justify-content: flex-start;
      }

      &__image {
        z-index: 300;
        height: 55px;
      }

      &__middle {
        .m-aol-se__content {
          float: left;
          position: relative;
          top: -40px;
          left: 3px;
          z-index: 300;
          margin-left: 12px;
          font-size: 11px;
        }
      }
    }
  }

  &__container_3 {
    border-radius: 16px;
    background: $bop-gradient;
    box-shadow: $bop-shadow;
    padding: 24px 20px 20px;

    .m-aol-se__content {
      .m-aol-se {
        &__heading {
          display: flex;
          justify-content: space-between;
          align-items: center;

          &--election {
            color: $gotham-dark;
            font-family: $font-family-poppins;
            font-size: 20px;
            font-style: normal;
            font-weight: 800;
            line-height: 28px;
            letter-spacing: -0.84px;
          }

          &--red {
            color: $election-red;
          }
          &--blue {
            color: $picasso;
          }

          &--map {
            text-decoration: none;
            color: $black;
            text-align: right;
            font-family: $font-family-sans-serif;
            font-size: 11px;
            font-style: normal;
            font-weight: 600;
            line-height: 12px; /* 109.091% */
          }
        }

        &__bop iframe {
          height: 140px;
          width: 100%;
          border: none;
          margin-top: 26px;
        }

        &__cta {
          display: none;
        }
      }

      .divider {
        display: none;
      }

    }

  }

  &__container_3.election_day_app.dark {
    background: $white;
  }
  &__container_3.election_day_app {
    cursor: pointer;

    .m-aol-se__content {
      .m-aol-se {

        &__cta {
          height: 32px;
          padding: 8px 16px;
          border-radius: 48px;
          background: $picasso;
          color: $white;
          text-align: center;
          font-family: $font-family-poppins;
          font-size: 11px;
          font-style: normal;
          border: none;
          font-weight: 600;
          line-height: 16px; /* 145.455% */

          &-container {
            display: flex;
            justify-content: center;
          }
        }
      }
    }

    &.cta {

      .m-aol-se__content {
        .m-aol-se {

          &__heading {
            &--map {
              display: none;
            }
          }

          &__cta {
            text-decoration: none;
            display: block;
          }
        }
      }
    }
  }
}

@media screen and (min-width: map-get($breakpoints, s)) {
  .m-aol-se {
    &__container_3 {
      border-radius: 20px;
      padding: 25px 28px 31px;

      .m-aol-se__content {
        .m-aol-se {
          &__heading {

            &--election {
              font-size: 28px;
            }

            &--map {
              font-size: 13px;
              font-weight: 400;
            }
          }
        }

        .divider {
          display: block;
          margin-top: 16px;
          height: 1px;
          border: 0;
          border-top: 1px solid $owl;
        }
      }
    }
  }
}